import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL } from '../config/urlConfig';
import Header from "../components/UserHeader";
import "../styles/CreateProject.css";

const CreateProject = ({ user }) => {
  const [type, setType] = useState("youtube");
  const [url, setUrl] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = () => {
      const token = localStorage.getItem('token');
      const userData = JSON.parse(localStorage.getItem('user'));
      
      console.log("Token from storage:", token);
      console.log("User data from storage:", userData);

      if (!token || !userData) {
        navigate('/login');
        return false;
      }
      return true;
    };

    checkAuth();
  }, [navigate]);

  const handleSave = async (e) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);

    try {
      const token = localStorage.getItem('token');
      
      console.log("Token for request:", token);

      if (!token) {
        setError("Session expired. Please login again.");
        navigate('/login');
        return;
      }

      const response = await fetch(`${API_URL}/create-project`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({ name, type, url }),
      });

      const data = await response.json();
      console.log("Response:", { status: response.status, data });

      if (response.ok) {
        navigate(`/crawl-config/${data.projectId}`);
      } else {
        if (response.status === 401) {
          localStorage.removeItem('user');
          localStorage.removeItem('token');
          navigate('/login');
        }
        setError(data.error || "Error creating project.");
      }
    } catch (error) {
      console.error("Error creating project:", error);
      setError("Error creating project. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="create-project">
      <Header user={user} />
      <main className="main-content">
        <div className="create-project-header">
          <div className="title-section">
            <h2>Create New Project</h2>
            <p className="subtitle">Configure your new tracking project</p>
          </div>
        </div>

        <div className="form-container">
          <form onSubmit={handleSave}>
            <div className="form-group">
              <label htmlFor="name">Project Name</label>
              <input
                id="name"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Ex.: My Channel or Site"
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="type">Project Type</label>
              <select 
                id="type" 
                value={type} 
                onChange={(e) => setType(e.target.value)}
              >
                <option value="youtube">YouTube Channel</option>
                <option value="site">Website</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="url">URL</label>
              <input
                id="url"
                type="text"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                placeholder={
                  type === "youtube" 
                    ? "https://www.youtube.com/@..." 
                    : "https://www.mysite.com"
                }
                required
              />
              <span className="input-hint">
                {type === "youtube" 
                  ? "Enter your YouTube channel URL" 
                  : "Enter your website URL"}
              </span>
            </div>

            {error && <p className="error-message">{error}</p>}

            <div className="form-actions">
              <button 
                type="button" 
                className="secondary-btn"
                onClick={() => navigate('/projects')}
              >
                Cancel
              </button>
              <button 
                type="submit" 
                className="primary-btn"
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <span className="spinner"></span>
                    Creating...
                  </>
                ) : (
                  'Create Project'
                )}
              </button>
            </div>
          </form>
        </div>
      </main>
    </div>
  );
};

export default CreateProject;
