import React from 'react';
import '../styles/Benefits.css';
import IconFast from '../assets/icons/fast-icon.png';
import IconExport from '../assets/icons/export-icon.png';
import IconCharts from '../assets/icons/charts-icon.png';
import IconCustom from '../assets/icons/custom-icon.png';

const Benefits = () => {
  return (
    <section id="features" className="benefits-section">
      <div className="benefits-container">
        <div className="benefits-header">
          <h2 className="benefits-title">
            Powerful Features for 
            <span className="highlight"> Better SEO</span>
          </h2>
          <p className="benefits-subtitle">
            Discover how Silent Puma can transform your website's performance with our comprehensive suite of tools.
          </p>
        </div>

        <div className="benefits-grid">
          <div className="benefit-card">
            <div className="benefit-icon">
              <img src={IconFast} alt="Fast Analysis" />
            </div>
            <h3>Fast Analysis</h3>
            <p>
              Analyze your website with unmatched speed and precision. Get actionable insights 
              in record time with our cutting-edge crawling engine.
            </p>
          </div>

          <div className="benefit-card">
            <div className="benefit-icon">
              <img src={IconExport} alt="Data Export" />
            </div>
            <h3>Data Export</h3>
            <p>
              Export results seamlessly in multiple formats. Share insights effortlessly 
              with your team or clients through CSV, Excel, or Google Sheets.
            </p>
          </div>

          <div className="benefit-card">
            <div className="benefit-icon">
              <img src={IconCharts} alt="Interactive Charts" />
            </div>
            <h3>Interactive Charts</h3>
            <p>
              Visualize your data with stunning interactive charts. Identify trends 
              and opportunities with our intuitive visualization tools.
            </p>
          </div>

          <div className="benefit-card">
            <div className="benefit-icon">
              <img src={IconCustom} alt="Custom Reports" />
            </div>
            <h3>Custom Reports</h3>
            <p>
              Create personalized reports tailored to your needs. Focus on the metrics 
              that matter most to your strategy.
            </p>
          </div>
        </div>
      </div>

      <div className="benefits-background">
        <div className="gradient-overlay"></div>
      </div>
    </section>
  );
};

export default Benefits;
