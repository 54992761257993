import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DefaultAvatar from '../assets/icons/default-avatar.png';
import { API_URL } from '../config/urlConfig';
import "../styles/UserHeader.css";
import { getSupportType } from '../config/planConfigs';
import { FaEnvelope, FaComment, FaHeadset } from 'react-icons/fa';
import SupportEmailModal from './SupportEmailModal';

const Header = ({ user }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const supportType = getSupportType(user.plan);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    navigate("/login");
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  // Função para construir a URL completa do avatar
  const getAvatarUrl = (avatarPath) => {
    if (!avatarPath) return DefaultAvatar;
    if (avatarPath.startsWith('http')) return avatarPath;
    return `${API_URL}${avatarPath}`;
  };

  const openChat = () => {
    // Aqui você pode integrar com seu sistema de chat
    // Por exemplo, Intercom, Zendesk, etc.
    if (window.Intercom) {
      window.Intercom('show');
    } else {
      // Fallback para email se o chat não estiver disponível
      window.location.href = `mailto:support@silentpuma.com?subject=Support Request - ${user.name}`;
    }
  };

  const openDedicatedSupport = () => {
    // Aqui você pode abrir um modal com os detalhes do gerente dedicado
    // ou redirecionar para uma página específica
    const supportEmail = user.dedicated_support_email || 'enterprise@silentpuma.com';
    window.location.href = `mailto:${supportEmail}?subject=Support Request - ${user.name}`;
  };

  const renderSupportButton = () => {
    switch (supportType) {
      case 'email':
        return (
          <button 
            onClick={() => setIsEmailModalOpen(true)} 
            className="support-button email"
          >
            <FaEnvelope /> Contact Support
          </button>
        );
      
      case 'chat':
      case 'priority_chat':
        return (
          <button 
            onClick={openChat} 
            className="support-button chat"
          >
            <FaComment /> Open Chat
          </button>
        );
      
      case 'dedicated':
        return (
          <button 
            onClick={openDedicatedSupport} 
            className="support-button dedicated"
          >
            <FaHeadset /> Contact Your Manager
          </button>
        );
      
      default:
        return null;
    }
  };

  return (
    <>
      <header className="user-header">
        <button
          className={`menu-icon ${isSidebarOpen ? "active" : ""}`}
          onClick={() => setSidebarOpen(!isSidebarOpen)}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>

        <h1 className="user-header-title">
          <Link to="/user-home">Silent Puma</Link>
        </h1>

        <div className="user-header-right">
          <div className="user-profile">
            <span className="user-greeting">Hi, {user?.name?.split(' ')[0]}!</span>
            <div className="user-avatar">
              <img
                src={getAvatarUrl(user?.avatar)}
                alt="User Avatar"
                onError={(e) => {
                  console.log('Error loading image:', e);
                  e.target.src = DefaultAvatar;
                }}
              />
            </div>
          </div>
        </div>

        <div 
          className={`sidebar-overlay ${isSidebarOpen ? "open" : ""}`}
          onClick={closeSidebar}
        />

        <div className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
          <ul className="sidebar-list">
            <li className="sidebar-section">Settings</li>
            <li><Link to="/settings">My Profile</Link></li>
            <li><Link to="/payments">Payments</Link></li>
            <hr />
            <li className="sidebar-section">Projects</li>
            <li><Link to="/projects">My Projects</Link></li>
            <li><Link to="/create-project">Create Project</Link></li>
            <hr />
            <li className="sidebar-section">Resource</li>
            <li><Link to="/resource-usage">Resource Usage</Link></li>
            <li><Link to="/api-access">API Access & Tokens</Link></li>
            <hr />
            <li className="sidebar-section">Support</li>
            <li>{renderSupportButton()}</li>
            <hr />
            <li className="sidebar-section">Terms</li>
            <li><Link to="/privacy">Privacy & Contracts</Link></li>
            <hr />
            <li className="sidebar-section">Social</li>
            <li className="social-links">
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">Instagram</a>
              <a href="https://www.linkedin.com/company/silent-puma/about/?viewAsMember=true" target="_blank" rel="noopener noreferrer">Linkedin</a>
            </li>
            <hr />
            <li><button onClick={handleLogout} className="logout-button">Logout</button></li>
          </ul>
        </div>
      </header>

      <SupportEmailModal
        isOpen={isEmailModalOpen}
        onClose={() => setIsEmailModalOpen(false)}
        userName={user?.name}
      />
    </>
  );
};

export default Header;