import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaUser } from 'react-icons/fa';
import '../styles/Header.css';

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  React.useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <header className={`header ${isScrolled ? 'scrolled' : ''}`}>
      <div className="header-container">
        <div className="logo">
          <Link to="/">
            <img src="/images/logo.png" alt="Silent Puma Logo" />
            <span>Silent Puma</span>
          </Link>
        </div>
        
        <nav className="navigation">
          <ul>
            <li>
              <a 
                href="#features" 
                onClick={(e) => {
                  e.preventDefault();
                  scrollToSection('features');
                }}
              >
                Features
              </a>
            </li>
            <li>
              <a 
                href="#pricing" 
                onClick={(e) => {
                  e.preventDefault();
                  scrollToSection('pricing');
                }}
              >
                Pricing
              </a>
            </li>
            <li><Link to="/docs">Documentation</Link></li>
            <li><Link to="/blog">Blog</Link></li>
            <li>
              <a 
                href="#contact" 
                onClick={(e) => {
                  e.preventDefault();
                  scrollToSection('contact');
                }}
              >
                Contact
              </a>
            </li>
          </ul>
        </nav>

        <div className="auth-buttons">
          <Link to="/login" className="login-btn" aria-label="Login">
            <FaUser />
          </Link>
          <Link to="/signup" className="try-btn">Try It!</Link>
        </div>
      </div>
    </header>
  );
};

export default Header;