export const planConfigs = {
  Basic: {
    limits: {
      projects: 1,
      pages: 3000
    },
    features: {
      dashboard: false,
      api_access: false,
      custom_integrations: false,
      data_retention_days: 7,
      support_type: 'email',
      export_formats: ['csv']
    }
  },
  Guru: {
    limits: {
      projects: 3,
      pages: 20000
    },
    features: {
      dashboard: true,
      api_access: false,
      custom_integrations: false,
      data_retention_days: 30,
      support_type: 'chat',
      export_formats: ['csv', 'json']
    }
  },
  Pro: {
    limits: {
      projects: 8,
      pages: 80000
    },
    features: {
      dashboard: true,
      api_access: true,
      custom_integrations: true,
      data_retention_days: 90,
      support_type: 'priority_chat',
      export_formats: ['csv', 'json', 'xml']
    }
  },
  Business: {
    limits: {
      projects: "Unlimited",
      pages: "Unlimited"
    },
    features: {
      dashboard: true,
      api_access: true,
      custom_integrations: true,
      data_retention_days: "Unlimited",
      support_type: 'dedicated',
      export_formats: ['csv', 'json', 'xml', 'custom']
    }
  }
};

export const checkFeatureAccess = (userPlan, feature) => {
  const planConfig = planConfigs[userPlan];
  if (!planConfig) return false;
  return planConfig.features[feature] || false;
};

export const getDataRetentionDays = (userPlan) => {
  const planConfig = planConfigs[userPlan];
  if (!planConfig) return 7; // default to Basic plan retention
  return planConfig.features.data_retention_days;
};

export const getSupportType = (userPlan) => {
  const planConfig = planConfigs[userPlan];
  if (!planConfig) return 'email';
  return planConfig.features.support_type;
}; 