import React, { useState, useEffect } from "react";
import Header from "../components/UserHeader";
import { API_URL } from '../config/urlConfig';
import DefaultAvatar from '../assets/icons/default-avatar.png';
import "../styles/UserSettings.css";

const UserSettings = ({ user, updateUser }) => {
  const [avatar, setAvatar] = useState(user?.avatar || DefaultAvatar);
  const [message, setMessage] = useState({ type: '', text: '' });
  const [usage, setUsage] = useState({ projects: 0, pages: 0 });
  const [plan, setPlan] = useState(user?.plan || "Basic");
  const [isUploading, setIsUploading] = useState(false);
  const [showBusinessModal, setShowBusinessModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: user?.name || '',
    email: user?.email || '',
    message: 'I\'m interested in the Business Plan.',
    company: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    fetchUsage();
  }, [user.id]);

  const fetchUsage = async () => {
    try {
      const response = await fetch(
        `${API_URL}/user-usage`,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        }
      );
      const data = await response.json();
      if (response.ok) {
        setUsage(data.usage);
        setPlan(data.plan);
      }
    } catch (error) {
      console.error("Error fetching usage data:", error);
      setMessage({ type: 'error', text: 'Error fetching usage data' });
    }
  };

  const handleAvatarUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setIsUploading(true);
    const formData = new FormData();
    formData.append("avatar", file);

    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_URL}/update-avatar`, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: formData
      });

      const data = await response.json();
      
      if (response.ok) {
        const fullAvatarUrl = `${API_URL}${data.avatarUrl}`;
        console.log('Full avatar URL:', fullAvatarUrl);
        
        setAvatar(fullAvatarUrl);
        updateUser({ ...user, avatar: fullAvatarUrl });
        setMessage({ type: 'success', text: 'Profile photo updated successfully!' });
      } else {
        setMessage({ type: 'error', text: data.error || 'Error updating profile photo.' });
      }
    } catch (error) {
      console.error('Upload error:', error);
      setMessage({ type: 'error', text: 'Error uploading file. Please try again.' });
    } finally {
      setIsUploading(false);
    }
  };

  const handleUpgrade = async (plan) => {
    if (plan === "Business") {
      setShowBusinessModal(true);
      return;
    }

    try {
      setIsLoading(true);
      const token = localStorage.getItem('token');
      
      const response = await fetch(`${API_URL}/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          plan: plan,
          priceId: getPriceId(plan) // Função helper para pegar o priceId correto
        })
      });

      const data = await response.json();

      if (response.ok) {
        window.location.href = data.url; // Redireciona para o Stripe Checkout
      } else {
        setMessage({ type: 'error', text: data.error || 'Error creating checkout session' });
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage({ type: 'error', text: 'Error processing upgrade request' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleContactClick = () => {
    setShowBusinessModal(false);
    const contactForm = document.getElementById('contact');
    if (contactForm) {
      contactForm.scrollIntoView({ 
        behavior: 'smooth',
        block: 'start'
      });
    }
  };

  // Helper function para pegar o priceId correto
  const getPriceId = (plan) => {
    const priceIds = {
      'Guru': 'price_1QpAxjJjS8YpYLc9Ubbj54WL',
      'Pro': 'price_1QpAynJjS8YpYLc9nCd0GWPu'
    };
    return priceIds[plan];
  };

  const handleCancelSubscription = async () => {
    if (!window.confirm("Are you sure you want to cancel your subscription?")) return;

    try {
      const response = await fetch(`${API_URL}/cancel-subscription`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userId: user.id }),
      });

      const data = await response.json();
      if (response.ok) {
        updateUser({ ...user, plan: "basic" }); // Atualiza para Basic no frontend
        setMessage({ type: 'success', text: 'Your subscription has been canceled. You are now on the Basic plan.' });
      } else {
        setMessage({ type: 'error', text: data.error || 'Error canceling subscription.' });
      }
    } catch (error) {
      console.error("Error canceling subscription:", error);
      setMessage({ type: 'error', text: 'Error processing the cancellation.' });
    }
  };

  const handleDeleteAccount = async () => {
    if (!window.confirm("Are you sure you want to delete your account? This action is irreversible.")) return;

    try {
      const response = await fetch(`${API_URL}/delete-account`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userId: user.id }),
      });

      const data = await response.json();
      if (response.ok) {
        setMessage({ type: 'success', text: 'Your account has been permanently deleted.' });
        setTimeout(() => {
          window.location.href = "/"; // Redirects to Home
        }, 3000);
      } else {
        setMessage({ type: 'error', text: data.error || 'Error deleting account.' });
      }
    } catch (error) {
      console.error("Error deleting account:", error);
      setMessage({ type: 'error', text: 'Error deleting account.' });
    }
  };

  const planLimits = {
    Basic: { projects: 1, pages: 3000 },
    Guru: { projects: 3, pages: 20000 },
    Pro: { projects: 8, pages: 80000 },
    Business: { projects: "Unlimited", pages: "Unlimited" }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await fetch(`${API_URL}/contact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(formData)
      });

      const data = await response.json();

      if (response.ok) {
        setMessage({ type: 'success', text: 'Message sent successfully! We\'ll contact you soon.' });
        setShowBusinessModal(false);
      } else {
        setMessage({ type: 'error', text: data.error || 'Error sending message' });
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage({ type: 'error', text: 'Error sending message' });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <div className="settings-page">
      <Header user={user} />
      <div className="settings-content">
        <div className="settings-grid">
          {/* Profile Section */}
          <div className="settings-card">
            <h2>Profile</h2>
            <div className="profile-section">
              <div className="avatar-section">
                <div className="avatar-container">
                  <img src={avatar} alt="Profile" className="profile-avatar" />
                  <label className="avatar-upload-button">
                    <input 
                      type="file" 
                      accept="image/jpeg,image/png,image/webp" 
                      onChange={handleAvatarUpload}
                      disabled={isUploading}
                    />
                    {isUploading ? 'Uploading...' : 'Change Photo'}
                  </label>
                </div>
              </div>
              <div className="profile-info">
                <div className="info-item">
                  <label>Name</label>
                  <p>{user.name}</p>
                </div>
                <div className="info-item">
                  <label>Email</label>
                  <p>{user.email}</p>
                </div>
              </div>
            </div>
          </div>

          {/* Plan Section */}
          <div className="settings-card">
            <h2>Current Plan - {plan}</h2>
            <div className="usage-stats">
              <div className="usage-item">
                <div className="usage-header">
                  <h3>Projects</h3>
                  <span className="usage-numbers">
                    {usage.projects?.used || 0} / {planLimits[plan]?.projects || 0}
                  </span>
                </div>
                <div className="usage-bar">
                  <div 
                    className="usage-progress" 
                    style={{ 
                      width: `${Math.min(((usage.projects?.used || 0) / (planLimits[plan]?.projects || 1)) * 100, 100)}%`
                    }}
                  />
                </div>
              </div>
              
              <div className="usage-item">
                <div className="usage-header">
                  <h3>Pages/Videos</h3>
                  <span className="usage-numbers">
                    {usage.pages?.used || 0} / {planLimits[plan]?.pages || 0}
                  </span>
                </div>
                <div className="usage-details">
                  <div className="usage-detail-item">
                    <span className="detail-label">YouTube Videos:</span>
                    <span className="detail-number">{usage.pages?.details?.youtube || 0}</span>
                  </div>
                  <div className="usage-detail-item">
                    <span className="detail-label">Website Pages:</span>
                    <span className="detail-number">{usage.pages?.details?.sites || 0}</span>
                  </div>
                </div>
                <div className="usage-bar">
                  <div 
                    className="usage-progress" 
                    style={{ 
                      width: `${Math.min(((usage.pages?.used || 0) / (planLimits[plan]?.pages || 1)) * 100, 100)}%`
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="plan-actions">
              {plan === "Basic" && (
                <>
                  <button 
                    onClick={() => handleUpgrade("Guru")}
                    disabled={isLoading}
                    className={isLoading ? 'loading' : ''}
                  >
                    {isLoading ? 'Processing...' : 'Upgrade to Guru'}
                  </button>
                  <button 
                    onClick={() => handleUpgrade("Pro")}
                    disabled={isLoading}
                    className={isLoading ? 'loading' : ''}
                  >
                    {isLoading ? 'Processing...' : 'Upgrade to Pro'}
                  </button>
                  <button 
                    onClick={() => handleUpgrade("Business")}
                    disabled={isLoading}
                  >
                    Contact for Business Plan
                  </button>
                </>
              )}
              {plan === "Guru" && (
                <>
                  <button 
                    onClick={() => handleUpgrade("Pro")}
                    disabled={isLoading}
                    className={isLoading ? 'loading' : ''}
                  >
                    {isLoading ? 'Processing...' : 'Upgrade to Pro'}
                  </button>
                  <button 
                    onClick={() => handleUpgrade("Business")}
                    disabled={isLoading}
                  >
                    Contact for Business Plan
                  </button>
                </>
              )}
              {plan === "Pro" && (
                <button 
                  onClick={() => handleUpgrade("Business")}
                  disabled={isLoading}
                >
                  Contact for Business Plan
                </button>
              )}
            </div>
          </div>

          {/* Danger Zone */}
          <div className="settings-card danger-zone">
            <h2>Danger Zone</h2>
            <div className="danger-actions">
              {plan !== "Basic" && (
                <button onClick={handleCancelSubscription} className="cancel-subscription">
                  Cancel Subscription
                </button>
              )}
              <button onClick={handleDeleteAccount} className="delete-account">
                Delete Account
              </button>
            </div>
          </div>
        </div>

        {message.text && (
          <div className={`message ${message.type}`}>
            {message.text}
          </div>
        )}
      </div>

      {/* Modal para plano Business com formulário integrado */}
      {showBusinessModal && (
        <div className="modal-overlay">
          <div className="modal-content business-inquiry">
            <h2>Upgrade to Business Plan</h2>
            <p className="modal-description">
              Tell us about your needs and we'll create a custom solution for your business.
            </p>
            
            <form onSubmit={handleFormSubmit} className="business-form">
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                  disabled={isSubmitting}
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                  disabled={isSubmitting}
                />
              </div>

              <div className="form-group">
                <label htmlFor="company">Company</label>
                <input
                  type="text"
                  id="company"
                  name="company"
                  value={formData.company}
                  onChange={handleInputChange}
                  required
                  disabled={isSubmitting}
                />
              </div>

              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  rows="4"
                  required
                  disabled={isSubmitting}
                />
              </div>

              <div className="modal-actions">
                <button 
                  type="submit"
                  className="primary-btn"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Sending...' : 'Send Message'}
                </button>
                <button 
                  type="button"
                  className="secondary-btn"
                  onClick={() => setShowBusinessModal(false)}
                  disabled={isSubmitting}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserSettings;
