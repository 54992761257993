import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { API_URL } from '../config/urlConfig';
import "../styles/CompleteSignup.css";
import { FaUser, FaLock, FaEnvelope } from "react-icons/fa";

const CompleteSignup = ({ setUser }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: ""
  });
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const sessionId = params.get("session_id");
  const emailFromUrl = params.get("email");
  const fromPlans = !emailFromUrl;

  useEffect(() => {
    if (!sessionId) {
      navigate("/signup");
    }
    if (emailFromUrl) {
      setFormData(prev => ({ ...prev, email: emailFromUrl }));
    }
  }, [sessionId, emailFromUrl, navigate]);

  const validatePassword = (password) => {
    const errors = [];
    
    if (password.length < 8) {
      errors.push("Password must be at least 8 characters long");
    }
    if (!/[A-Z]/.test(password)) {
      errors.push("Password must contain at least one uppercase letter");
    }
    if (!/[a-z]/.test(password)) {
      errors.push("Password must contain at least one lowercase letter");
    }
    if (!/[0-9]/.test(password)) {
      errors.push("Password must contain at least one number");
    }
    if (!/[!@#$%^&*]/.test(password)) {
      errors.push("Password must contain at least one special character (!@#$%^&*)");
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;

    const passwordErrors = validatePassword(formData.password);
    if (passwordErrors.length > 0) {
      setError(passwordErrors.join('\n'));
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    setIsSubmitting(true);
    setError("");

    try {
      const response = await fetch(`${API_URL}/complete-signup`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          sessionId,
          email: emailFromUrl || formData.email,
          name: formData.name,
          password: formData.password
        }),
      });

      const data = await response.json();

      if (response.ok) {
        localStorage.removeItem('token');
        localStorage.setItem('token', data.token);
        setUser(data.user);
        navigate("/user-home");
      } else {
        setError(data.error || "Error completing signup.");
      }
    } catch (error) {
      setError("Connection error. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderPasswordRequirements = () => (
    <div className="password-requirements">
      <p>Password must contain:</p>
      <ul>
        <li>At least 8 characters</li>
        <li>At least one uppercase letter</li>
        <li>At least one lowercase letter</li>
        <li>At least one number</li>
        <li>At least one special character (!@#$%^&*)</li>
      </ul>
    </div>
  );

  return (
    <div className="complete-signup-container">
      <div className="complete-signup-box">
        <div className="complete-signup-header">
          <img src="/images/logo.png" alt="Silent Puma Logo" className="complete-signup-logo" />
          <h1>Complete Registration</h1>
        </div>
        
        <form onSubmit={handleSubmit} className="complete-signup-form">
          <div className="form-group">
            <input
              type="text"
              placeholder="Your name"
              value={formData.name}
              onChange={(e) => setFormData({...formData, name: e.target.value})}
              required
              disabled={isSubmitting}
              className="complete-signup-input"
            />
          </div>
          
          {fromPlans && (
            <div className="form-group">
              <input
                type="email"
                placeholder="Your email"
                value={formData.email}
                onChange={(e) => setFormData({...formData, email: e.target.value})}
                required
                disabled={isSubmitting}
                className="complete-signup-input"
              />
            </div>
          )}

          <div className="form-group">
            <input
              type="password"
              placeholder="Choose a password"
              value={formData.password}
              onChange={(e) => setFormData({...formData, password: e.target.value})}
              required
              disabled={isSubmitting}
              className="complete-signup-input"
            />
          </div>

          {formData.password && renderPasswordRequirements()}

          <div className="form-group">
            <input
              type="password"
              placeholder="Confirm password"
              value={formData.confirmPassword}
              onChange={(e) => setFormData({...formData, confirmPassword: e.target.value})}
              required
              disabled={isSubmitting}
              className="complete-signup-input"
            />
          </div>

          <button 
            type="submit" 
            className={`complete-signup-button ${isSubmitting ? "submitting" : ""}`}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Processing..." : "Complete Registration"}
          </button>
        </form>
        {error && <p className="error-message">{error.split('\n').map((err, i) => <span key={i}>{err}</span>)}</p>}
      </div>
    </div>
  );
};

export default CompleteSignup; 