import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/UserHeader";
import { API_URL } from '../config/urlConfig';
import { Link } from "react-router-dom";
import { FaTrash } from "react-icons/fa";
import "../styles/Projects.css";

const Projects = ({ user }) => {
  const [projects, setProjects] = useState([]);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState("");
  const projectsPerPage = 6;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const token = localStorage.getItem('token');
        
        if (!token) {
          navigate('/login');
          return;
        }

        const response = await fetch(`${API_URL}/projects`, {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        });

        const data = await response.json();

        if (response.ok) {
          setProjects(data.projects);
        } else {
          if (response.status === 401) {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            navigate('/login');
          } else {
            setError("Error loading projects");
          }
        }
      } catch (error) {
        console.error("Error loading projects:", error);
        setError("Error loading projects");
      }
    };

    fetchProjects();
  }, []);

  const handleDeleteProject = async (projectId) => {
    if (!window.confirm("Are you sure you want to delete this project?")) return;

    try {
      const token = localStorage.getItem('token');
      
      if (!token) {
        navigate('/login');
        return;
      }

      const response = await fetch(`${API_URL}/delete-project/${projectId}`, {
        method: "DELETE",
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });
      
      if (response.ok) {
        setProjects(projects.filter((project) => project.id !== projectId));
      } else {
        if (response.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          navigate('/login');
        } else {
          setError("Error deleting project");
        }
      }
    } catch (error) {
      console.error("Error deleting project:", error);
      setError("Error deleting project");
    }
  };

  const handleDownloadProject = async (projectId) => {
    try {
      const token = localStorage.getItem('token');
      
      if (!token) {
        navigate('/login');
        return;
      }

      const response = await fetch(`${API_URL}/project/${projectId}/download`, {
        headers: {
          "Authorization": `Bearer ${token}`,
        }
      });

      if (response.ok) {
        // Converter a resposta para blob e fazer download
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `project-${projectId}-results.csv`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        if (response.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          navigate('/login');
        } else {
          setError("Error downloading results");
        }
      }
    } catch (error) {
      console.error("Error downloading results:", error);
      setError("Error downloading results");
    }
  };

  if (!user) {
    navigate('/login');
    return null;
  }

  const filteredProjects = projects.filter((project) => {
    return (
      (filter === "all" || project.type === filter) &&
      project.name.toLowerCase().includes(search.toLowerCase())
    );
  });

  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = filteredProjects.slice(indexOfFirstProject, indexOfLastProject);

  return (
    <div className="projects">
      <Header user={user} />
      <main className="main-content">
        <div className="projects-header">
          <div className="title-section">
            <h2>My Projects</h2>
            <p className="project-count">
              {filteredProjects.length} {filteredProjects.length === 1 ? 'project' : 'projects'} total
            </p>
          </div>
          <Link to="/create-project" className="create-project-btn">
            <span>+</span> Create Project
          </Link>
        </div>

        {error && <p className="error-message">{error}</p>}
        
        <div className="filters-section">
          <div className="search-box">
            <input
              type="text"
              placeholder="Search projects..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className="filter-box">
            <select value={filter} onChange={(e) => setFilter(e.target.value)}>
              <option value="all">All Types</option>
              <option value="youtube">YouTube</option>
              <option value="site">Website</option>
            </select>
          </div>
        </div>

        <div className="projects-grid">
          {currentProjects.length > 0 ? (
            currentProjects.map((project) => (
              <div key={project.id} className="project-card">
                <div className="project-header">
                  <span className={`project-type ${project.type}`}>
                    {project.type === "youtube" ? "YouTube" : "Website"}
                  </span>
                  <button 
                    className="delete-project" 
                    onClick={() => handleDeleteProject(project.id)}
                    title="Delete project"
                  >
                    <FaTrash />
                  </button>
                </div>
                
                <div className="project-info">
                  <h3>{project.name}</h3>
                  <p className="project-date">
                    Created {new Date(project.created_at).toLocaleDateString()}
                  </p>
                </div>

                <div className="project-actions">
                  <Link to={`/project/${project.id}/runs`} className="action-btn runs-btn">
                    View Runs
                  </Link>
                  {user.plan === 'Basic' ? (
                    <button 
                      onClick={() => handleDownloadProject(project.id)} 
                      className="action-btn download-btn"
                    >
                      Download CSV
                    </button>
                  ) : (
                    <Link to={`/dashboard/${project.id}`} className="action-btn dashboard-btn">
                      Dashboard
                    </Link>
                  )}
                </div>
              </div>
            ))
          ) : (
            <div className="no-projects">
              <p>No projects found</p>
              <Link to="/create-project" className="create-project-btn">
                Create your first project
              </Link>
            </div>
          )}
        </div>

        {filteredProjects.length > projectsPerPage && (
          <div className="pagination">
            <button 
              onClick={() => setCurrentPage(currentPage - 1)} 
              disabled={currentPage === 1}
              className="page-btn"
            >
              Previous
            </button>
            <span className="page-info">
              Page {currentPage} of {Math.ceil(filteredProjects.length / projectsPerPage)}
            </span>
            <button
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={indexOfLastProject >= filteredProjects.length}
              className="page-btn"
            >
              Next
            </button>
          </div>
        )}
      </main>
    </div>
  );
};

export default Projects;