import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { API_URL } from '../config/urlConfig';
import '../styles/AuthCallback.css';


const AuthCallback = ({ setUser }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    
    if (token) {
      // Buscar dados do usuário
      fetch(`${API_URL}/me`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(res => res.json())
      .then(userData => {
        const user = {
          ...userData,
          token
        };
        
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('token', token);
        setUser(user);
        navigate('/user-home');
      })
      .catch(() => {
        navigate('/login?error=auth_failed');
      });
    } else {
      navigate('/login?error=no_token');
    }
  }, [navigate, setUser, location]);

  return (
    <div className="auth-callback-container">
      <div className="auth-callback-box">
        <p>Authenticating...</p>
      </div>
    </div>
  );
};

export default AuthCallback; 