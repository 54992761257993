import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { API_URL } from '../config/urlConfig';
import { FaCheck } from 'react-icons/fa';
import Header from '../components/Header';
import '../styles/Plans.css';

const Plans = () => {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const plans = [
    {
      name: 'Basic',
      price: '$0.00',
      priceId: 'price_1QehodJjS8YpYLc9WVN3Bljd',
      features: [
        'Up to 2 simultaneous projects',
        'Up to 4000 crawled pages/videos',
        '14 days history',
        'Email support',
        'Free updates',
        'CSV export',
        'Dashboards'
      ]
    },
    {
      name: 'Guru',
      price: '$19.00',
      priceId: 'price_1QpAxjJjS8YpYLc9Ubbj54WL',
      features: [
        'Up to 3 simultaneous projects',
        'Up to 20000 crawled pages/videos',
        '45 days history',
        'Everything in Basic'
      ]
    },
    {
      name: 'Pro',
      price: '$39.00',
      priceId: 'price_1QpAynJjS8YpYLc9nCd0GWPu',
      popular: true,
      features: [
        'Up to 6 simultaneous projects',
        'Up to 80000 crawled pages/videos',
        '90 days history',
        'Everything in Guru',
      ]
    },

    {
      name: 'Enterprise',
      price: 'Contact Us',
      priceId: null,
      features: [
        'Unlimited projects',
        'Unlimited pages',
        'Dedicated 24/7 support',
        'Priority updates',
        'All export formats',
        'Full history',
        'Dedicated API',
      ]
    }
  ];

  const handleSelectPlan = async (priceId) => {
    if (isLoading) return;
    
    setIsLoading(true);
    setError('');

    try {
      const response = await fetch(`${API_URL}/create-checkout-session`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 
          priceId,
          fromPlans: true
        }),
      });

      const data = await response.json();

      if (response.ok) {
        window.location.href = data.url;
      } else {
        setError(data.error || "Request processing error.");
      }
    } catch (error) {
      setError("Connection error. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="plans-page">
      <Header />
      <div className="plans-header">
        <h1>Choose Your Plan</h1>
        <p>Start your documentation journey with Silent Puma</p>
      </div>

      <div className="plans-container">
        {plans.map((plan) => (
          <div 
            key={plan.name} 
            className={`plan-card ${plan.popular ? 'popular' : ''}`}
          >
            {plan.popular && <div className="popular-tag">Most Popular</div>}
            
            <h2>{plan.name}</h2>
            <div className="price">
              <span className="amount">{plan.price}</span>
              <span className="period">/month</span>
            </div>

            <ul className="features">
              {plan.features.map((feature, index) => (
                <li key={index}>
                  <FaCheck className="check-icon" />
                  {feature}
                </li>
              ))}
            </ul>

            <button
              className={`select-plan-button ${isLoading ? 'loading' : ''}`}
              onClick={() => handleSelectPlan(plan.priceId)}
              disabled={isLoading}
            >
              {isLoading ? 'Processing...' : 'Get Started'}
            </button>
          </div>
        ))}
      </div>

      {error && <p className="error-message">{error}</p>}

      <div className="plans-footer">
        <p>
          Already have an account? <Link to="/login">Sign in</Link>
        </p>
        <p className="guarantee">
          7-day money-back guarantee
        </p>
      </div>
    </div>
  );
};

export default Plans; 