import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { API_URL } from '../config/urlConfig';
import "../styles/Signup.css";
import { FaEnvelope, FaGoogle } from "react-icons/fa";

const SignupPage = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const handleEmailSignup = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;

    setIsSubmitting(true);
    setError("");

    try {
      // Primeiro, verificar se o email já existe
      const checkResponse = await fetch(`${API_URL}/auth/check-email`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      });

      const checkData = await checkResponse.json();

      if (!checkResponse.ok) {
        throw new Error(checkData.error || "Email already registered");
      }

      // Se o email não existe, prosseguir com o checkout
      const response = await fetch(`${API_URL}/create-checkout-session`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: 'include', // Importante para cookies
        body: JSON.stringify({ 
          email,
          priceId: 'price_1QehodJjS8YpYLc9WVN3Bljd',
          fromPlans: false,
          signupMethod: 'email'
        }),
      });

      const data = await response.json();

      if (response.ok) {
        // Armazenar email temporariamente para uso posterior
        sessionStorage.setItem('signupEmail', email);
        window.location.href = data.url;
      } else {
        setError(data.error || "Error processing request.");
      }
    } catch (error) {
      setError(error.message || "Connection error. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleGoogleSignup = () => {
    // Armazenar que o signup está sendo feito via Google
    sessionStorage.setItem('signupMethod', 'google');
    
    // Redirecionar para a rota de autenticação Google
    const googleAuthUrl = `${API_URL}/auth/google/signup`;
    window.location.href = googleAuthUrl;
  };

  return (
    <div className="signup-container">
      <div className="signup-box">
        <div className="signup-header">
          <img src="/images/logo.png" alt="Silent Puma" className="signup-logo" />
          <h1>Create Account</h1>
        </div>
        {error && <div className="error-message">{error}</div>}
        
        <form onSubmit={handleEmailSignup} className="signup-form">
          <div className="form-group">
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Full Name"
              required
              disabled={isSubmitting}
            />
          </div>
          <div className="form-group">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              required
              disabled={isSubmitting}
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              required
              disabled={isSubmitting}
            />
          </div>
          
          <button 
            type="submit" 
            className="signup-button email-button"
            disabled={isSubmitting}
          >
            <FaEnvelope /> {isSubmitting ? "Creating Account..." : "Sign up with Email"}
          </button>
        </form>

        <div className="divider">or</div>

        <button 
          onClick={handleGoogleSignup}
          className="signup-button google-button"
          disabled={isSubmitting}
        >
          <FaGoogle /> Sign up with Google
        </button>

        <div className="signup-footer">
          <Link to="/login">Already have an account? Login</Link>
        </div>
      </div>
    </div>
  );
};

export default SignupPage;
