import React from 'react';
import Header from '../components/Header';
import HeroSection from '../components/HeroSection';
import Benefits from '../components/Benefits';
import Pricing from '../components/Pricing';
import ContactForm from '../components/ContactForm';

const Home = () => {
  return (
    <div>
      <Header />
      <HeroSection />
      <Benefits />
      <Pricing />
      <ContactForm />
    </div>
  );
};

export default Home;