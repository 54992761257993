import React from 'react';
import '../styles/HeroSection.css';
import { Link } from 'react-router-dom';

const HeroSection = () => {
  return (
    <section className="hero-section">
      <div className="hero-content">
        <h1 className="hero-title">
          Discover Your Website's
          <span className="highlight"> Hidden Potential</span>
        </h1>
        
        <p className="hero-description">
          Silent Puma is your AI-powered SEO companion, helping you uncover insights
          and optimize your web presence with unprecedented precision.
        </p>

        <div className="hero-cta">
          <Link to="/signup" className="cta-button primary">
            Start Analyzing Free
          </Link>
          <Link to="/demo" className="cta-button secondary">
            Watch Demo
          </Link>
        </div>

        <div className="hero-stats">
          <div className="stat-item">
            <span className="stat-number">500K+</span>
            <span className="stat-label">Pages Analyzed</span>
          </div>
          <div className="stat-item">
            <span className="stat-number">15K+</span>
            <span className="stat-label">Active Users</span>
          </div>
          <div className="stat-item">
            <span className="stat-number">99.9%</span>
            <span className="stat-label">Uptime</span>
          </div>
        </div>
      </div>

      <div className="hero-visual">
        <div className="gradient-sphere">
          <div className="sphere-1"></div>
          <div className="sphere-2"></div>
          <div className="sphere-3"></div>
        </div>
        <div className="floating-elements">
          {/* Elementos decorativos que podem ser adicionados depois */}
        </div>
      </div>
    </section>
  );
};

export default HeroSection;

