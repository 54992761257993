import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { API_URL } from '../config/urlConfig';
import Header from "../components/UserHeader";
import "../styles/CrawlConfig.css";

const planLimits = {
  Basic: { projects: 1, pages: 3000 },
  Guru: { projects: 4, pages: 20000 },
  Pro: { projects: 10, pages: 80000 },
  Business: { projects: "Unlimited", pages: "Unlimited" },
};

const CrawlConfig = ({user}) => {
  const { projectId } = useParams();
  const [project, setProject] = useState(null);
  const [crawlName, setCrawlName] = useState("");
  const [maxItems, setMaxItems] = useState(100);
  const [userUsage, setUserUsage] = useState({ pagesUsed: 0 });
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        
        if (!token) {
          setError("Session expired. Please login again.");
          navigate('/login');
          return;
        }

        // Buscar projeto e uso do usuário em paralelo
        const [projectResponse, usageResponse] = await Promise.all([
          fetch(`${API_URL}/project/${projectId}`, {
            headers: {
              "Authorization": `Bearer ${token}`,
              "Content-Type": "application/json"
            }
          }),
          fetch(`${API_URL}/user/usage`, {
            headers: {
              "Authorization": `Bearer ${token}`,
              "Content-Type": "application/json"
            }
          })
        ]);

        const projectData = await projectResponse.json();
        const usageData = await usageResponse.json();

        if (projectResponse.ok) {
          setProject(projectData);
        }
        
        if (usageResponse.ok) {
          setUserUsage(usageData);
        }

      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Error loading configuration.");
      }
    };

    fetchData();
  }, [projectId, navigate]);

  const calculateRemainingItems = () => {
    const userPlan = user?.plan || 'Basic';
    const planLimit = planLimits[userPlan].pages;
    
    if (planLimit === "Unlimited") return Infinity;
    return planLimit - userUsage.pagesUsed;
  };

  const handleStartCrawl = async () => {
    if (!crawlName.trim()) {
      setError("Please enter a name for the run.");
      return;
    }

    const remainingItems = calculateRemainingItems();
    if (maxItems > remainingItems) {
      setError(`You only have ${remainingItems} items available in your ${user.plan} plan.`);
      return;
    }

    try {
      const token = localStorage.getItem('token');
      
      if (!token) {
        setError("Session expired. Please login again.");
        navigate('/login');
        return;
      }

      const response = await fetch(`${API_URL}/start-crawler`, {
        method: "POST",
        headers: { 
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({ 
          projectId, 
          type: project.type, 
          url: project.url, 
          crawlName,
          maxItems
        }),
      });

      const data = await response.json();

      if (response.ok) {
        navigate(`/project/${projectId}/runs`);
      } else {
        if (response.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          navigate('/login');
        } else {
          setError(data.error || "Error starting the crawler.");
        }
      }
    } catch (error) {
      console.error("Error starting crawler:", error);
      setError("Error starting the crawler.");
    }
  };

  if (!project) {
    return (
      <div className="crawl-config">
        <Header user={user} />
        <main className="main-content">
          <p className="loading">Loading...</p>
        </main>
      </div>
    );
  }

  const remainingItems = calculateRemainingItems();

  return (
    <div className="crawl-config">
      <Header user={user} />
      <main className="main-content">
        <div className="crawl-config-header">
          <div className="title-section">
            <h2>Run Configuration</h2>
            <p className="subtitle">Configure your tracking run for {project.name}</p>
          </div>
        </div>

        <div className="form-container">
          <div className="project-info">
            <div className="info-item">
              <span className="info-label">Project Type</span>
              <span className={`project-type ${project.type}`}>
                {project.type === "youtube" ? "YouTube" : "Website"}
              </span>
            </div>
            <div className="info-item">
              <span className="info-label">Target URL</span>
              <span className="project-url">{project.url}</span>
            </div>
            <div className="info-item">
              <span className="info-label">Plan Usage</span>
              <span className="plan-usage">
                {userUsage.pagesUsed} of {planLimits[user?.plan]?.pages === "Unlimited" ? "Unlimited" : planLimits[user?.plan]?.pages} items used
              </span>
            </div>
          </div>

          <form onSubmit={(e) => { e.preventDefault(); handleStartCrawl(); }}>
            <div className="form-group">
              <label htmlFor="crawlName">Run Name</label>
              <input
                id="crawlName"
                type="text"
                value={crawlName}
                onChange={(e) => setCrawlName(e.target.value)}
                placeholder="Ex.: Daily Check or Weekly Analysis"
                required
              />
              <span className="input-hint">
                Give your run a descriptive name to easily identify it later
              </span>
            </div>

            <div className="form-group">
              <label htmlFor="maxItems">
                Maximum Items to Crawl
                <span className="limit-info">
                  (Available: {remainingItems === Infinity ? "Unlimited" : remainingItems})
                </span>
              </label>
              <input
                id="maxItems"
                type="number"
                value={maxItems}
                onChange={(e) => setMaxItems(Math.min(Number(e.target.value), remainingItems))}
                min="1"
                max={remainingItems}
                required
              />
              <span className="input-hint">
                Specify how many {project.type === "youtube" ? "videos" : "pages"} to crawl
              </span>
            </div>

            {error && <p className="error-message">{error}</p>}

            <div className="form-actions">
              <button 
                type="button" 
                className="secondary-btn"
                onClick={() => navigate(`/project/${projectId}/runs`)}
              >
                Cancel
              </button>
              <button 
                type="submit" 
                className="primary-btn"
                disabled={!crawlName.trim() || maxItems < 1}
              >
                Start Run
              </button>
            </div>
          </form>
        </div>
      </main>
    </div>
  );
};

export default CrawlConfig;