import React from "react";
import { Link } from "react-router-dom";
import Header from "../components/UserHeader";
import "../styles/UserHome.css";

const UserHome = ({ user }) => {
  return (
    <div className="user-home">
      <Header user={user} />
      <div className="user-home-content">
        <div className="welcome-section">
          <h1>Welcome back, {user.name}!</h1>
          <p>Let's improve your SEO performance today.</p>
        </div>

        <div className="user-home-info-grid">
          <div className="user-home-info-card">
            <h2>Getting Started</h2>
            <p>Create your first project and start analyzing your website's SEO performance.</p>
            <Link to="/create-project" className="user-home-action-button">Create Project</Link>
          </div>

          <div className="user-home-info-card">
            <h2>How It Works</h2>
            <p>Silent Puma analyzes your website and provides actionable insights to improve your SEO ranking.</p>
            <Link to="/docs" className="user-home-action-button">Learn More</Link>
          </div>

          <div className="user-home-info-card">
            <h2>Run Analysis</h2>
            <p>Start a new analysis run to get fresh insights about your website's performance.</p>
            <Link to="/projects" className="user-home-action-button">View Projects</Link>
          </div>

          <div className="user-home-info-card">
            <h2>Resource Usage</h2>
            <p>Monitor your API usage and manage your subscription plan.</p>
            <Link to="/resource-usage" className="user-home-action-button">Check Usage</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserHome;
