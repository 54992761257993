import React, { useState } from 'react';
import '../styles/SupportEmailModal.css';
import { FaTimes } from 'react-icons/fa';

const SupportEmailModal = ({ isOpen, onClose, userName }) => {
  const [formData, setFormData] = useState({
    subject: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // Aqui você pode implementar a lógica de envio do email
      // Por exemplo, usando sua API
      await fetch(`${process.env.REACT_APP_API_URL}/support/email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          subject: formData.subject,
          message: formData.message,
          userName
        })
      });

      onClose();
      // Você pode adicionar uma notificação de sucesso aqui
    } catch (error) {
      console.error('Error sending support email:', error);
      // Você pode adicionar uma notificação de erro aqui
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="support-modal-overlay">
      <div className="support-modal">
        <button className="close-modal" onClick={onClose}>
          <FaTimes />
        </button>
        
        <h2>Contact Support</h2>
        
        <form onSubmit={handleSubmit} className="support-form">
          <div className="form-group">
            <label htmlFor="subject">Subject</label>
            <input
              type="text"
              id="subject"
              value={formData.subject}
              onChange={(e) => setFormData({ ...formData, subject: e.target.value })}
              required
              placeholder="How can we help you?"
            />
          </div>

          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              value={formData.message}
              onChange={(e) => setFormData({ ...formData, message: e.target.value })}
              required
              placeholder="Describe your issue or question..."
              rows={6}
            />
          </div>

          <button 
            type="submit" 
            className="submit-button"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Sending...' : 'Send Message'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default SupportEmailModal;