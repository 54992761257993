import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { API_URL } from '../config/urlConfig';
import { FaCheck } from 'react-icons/fa';
import '../styles/Pricing.css';

const PLANS = {
  monthly: {
    Basic: {
      price: '0',
      period: 'month',
      description: 'Perfect for getting started',
      priceId: 'price_1QehodJjS8YpYLc9WVN3Bljd',
      features: [
        '1 project',
        '3,000 pages/month',
        'Basic data export',
        'Email support',
        'Free Updates',
        '7-day data retention'
      ],
      isPopular: false
    },
    Guru: {
      price: '29',
      period: 'month',
      description: 'Best for growing teams',
      priceId: 'price_1R02mrJjS8YpYLc9pSJoxyGd',
      features: [
        '4 projects',
        '20,000 pages/month',
        'Advanced dashboard',
        'Chat support',
        'Everything in Basic',
        '30-day data retention'
      ],
      isPopular: true
    },
    Pro: {
      price: '49',
      period: 'month',
      description: 'For professional users',
      priceId: 'price_1R02o3JjS8YpYLc9vpR8BEVf',
      features: [
        '8 projects',
        '80,000 pages/month',
        'API access',
        'Custom Integrations',
        'Everything in Guru',
        '90-day data retention'
      ],
      isPopular: false
    },
    Business: {
      price: 'Custom',
      period: 'month',
      description: 'For large businesses',
      features: [
        'Unlimited projects',
        'Unlimited pages',
        'Dedicated support',
        'Everything in Pro',
        'Unlimited data retention',
      ],
      isPopular: false,
      isBusiness: true
    }
  },
  annual: {
    Basic: {
      price: '0',
      period: 'year',
      description: 'Perfect for getting started',
      priceId: 'price_1R02r9JjS8YpYLc9hTfamrFG',
      features: [
        '1 project',
        '3,000 pages/month',
        'Basic data export',
        'Email support',
        'Free Updates',
        '7-day data retention'
      ],
      isPopular: false
    },
    Guru: {
      price: '290',  // 29 * 10 (2 meses grátis)
      period: 'year',
      description: 'Best for growing teams',
      priceId: 'price_1QuxfEJjS8YpYLc9sADsg350',
      features: [
        '3 projects',
        '20,000 pages/month',
        'Advanced dashboard',
        'Chat support',
        'Everything in Basic',
        '30-day data retention'
      ],
      isPopular: false
    },
    Pro: {
      price: '490',  // 49 * 10 (2 meses grátis)
      period: 'year',
      description: 'For professional users',
      priceId: 'price_1R02EEJjS8YpYLc9uBd8VTV1',
      features: [
        '8 projects',
        '80,000 pages/month',
        'API access',
        'Custom Integrations',
        'Everything in Guru',
        '90-day data retention'
      ],
      isPopular: false
    },
    Business: {
      price: 'Custom',
      period: 'year',
      description: 'For large businesses',
      features: [
        'Unlimited projects',
        'Unlimited pages',
        'Dedicated support',
        'Everything in Pro',
        'Unlimited data retention',
      ],
      isPopular: false,
      isBusiness: true
    }
  }
};

const Pricing = () => {
  const [billingPeriod, setBillingPeriod] = useState('monthly');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);

  const handlePeriodToggle = () => {
    setBillingPeriod(prev => prev === 'monthly' ? 'annual' : 'monthly');
  };

  const handleChoosePlan = async (priceId) => {
    if (isLoading) return;
    
    setIsLoading(true);
    setError('');

    try {
      
      const response = await fetch(`${API_URL}/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ priceId }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      if (response.ok) {
        window.location.href = data.url;
      } else {
        setError(data.error || "Request processing error.");
      }
    } catch (err) {
      setError('Something went wrong. Please try again later.');
      console.error('Error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleContactClick = () => {
    setShowContactModal(false); // Fecha o modal
    // Scroll suave até o formulário de contato
    const contactForm = document.getElementById('contact');
    if (contactForm) {
      contactForm.scrollIntoView({ 
        behavior: 'smooth',
        block: 'start'
      });
    }
  };

  return (
    <section id="pricing" className="pricing-section">
      <div className="pricing-container">
        <div className="pricing-header">
          <h2>Choose Your Plan</h2>
          <p>Start optimizing your website today with our flexible plans</p>
        </div>

        {/* Billing Period Toggle */}
        <div className="billing-toggle">
          <span className={billingPeriod === 'monthly' ? 'active' : ''}>Monthly</span>
          <label className="switch">
            <input
              type="checkbox"
              checked={billingPeriod === 'annual'}
              onChange={handlePeriodToggle}
            />
            <span className="slider round"></span>
          </label>
          <span className={billingPeriod === 'annual' ? 'active' : ''}>
            Annual <span className="save-badge">Save 20%</span>
          </span>
        </div>

        <div className="pricing-grid">
        {Object.entries(PLANS[billingPeriod]).map(([planName, plan]) => (
            <div 
              key={planName}
              className={`pricing-card ${plan.isPopular ? 'popular' : ''}`}
            >
              {plan.isPopular && <div className="popular-badge">Most Popular</div>}

              <div className="plan-header">
                <h3>{planName}</h3>
                <p className="plan-description">{plan.description}</p>
                <div className="plan-price">
                  {!plan.isBusiness && <span className="currency">$</span>}
                  <span className="amount">{plan.price}</span>
                  {!plan.isBusiness && <span className="period">/{plan.period}</span>}
                </div>
              </div>

              <ul className="plan-features">
                {plan.features.map((feature, i) => (
                  <li key={i}>
                    <FaCheck className="check-icon" />
                    {feature}
                  </li>
                ))}
              </ul>

              {plan.isBusiness ? (
                <button
                  className="choose-plan-button"
                  onClick={() => setShowContactModal(true)}
                >
                  Contact Us
                </button>
              ) : (
                <button
                  className={`choose-plan-button ${isLoading ? 'loading' : ''}`}
                  onClick={() => handleChoosePlan(plan.priceId)}
                  disabled={isLoading}
                >
                  {isLoading ? 'Processing...' : 'Get Started'}
                </button>
              )}
            </div>
          ))}
        </div>

        {error && <div className="error-message">{error}</div>}
      </div>

      {showContactModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Contact Us for Business Plan</h2>
            <p>
              Let's discuss your specific needs and create a custom solution for your business.
            </p>
            <div className="contact-info">
              <p>Fill out our contact form and we'll get back to you shortly.</p>
            </div>
            <button 
              className="primary-btn"
              onClick={handleContactClick}
            >
              Go to Contact Form
            </button>
            <button 
              className="secondary-btn"
              onClick={() => setShowContactModal(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

export default Pricing;

