import React, { useState, useEffect } from "react";
import Header from "../components/UserHeader";
import { API_URL } from '../config/urlConfig';
import { useNavigate, Link } from "react-router-dom";
import "../styles/Payments.css";

const Payments = ({ user }) => {
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const token = localStorage.getItem('token');
        
        if (!token) {
          navigate('/login');
          return;
        }

        const response = await fetch(`${API_URL}/payments/history`, {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        });

        const data = await response.json();

        if (response.ok) {
          if (data.message) {
            setMessage(data.message);
          }
          const groupedPayments = groupPaymentsByMonth(data.payments);
          setPayments(groupedPayments);
        } else {
          if (response.status === 401) {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            navigate('/login');
          } else {
            setError("Erro ao carregar histórico de pagamentos");
          }
        }
      } catch (error) {
        console.error("Erro ao buscar pagamentos:", error);
        setError("Erro ao carregar histórico de pagamentos");
      } finally {
        setLoading(false);
      }
    };

    fetchPayments();
  }, [navigate]);

  const groupPaymentsByMonth = (payments) => {
    return payments.reduce((groups, payment) => {
      const date = new Date(payment.created * 1000);
      const monthYear = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
      
      if (!groups[monthYear]) {
        groups[monthYear] = [];
      }
      
      groups[monthYear].push(payment);
      return groups;
    }, {});
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(amount / 100);
  };

  const formatDate = (timestamp) => {
    return new Date(timestamp * 1000).toLocaleDateString('pt-BR');
  };

  if (loading) {
    return (
      <div className="payments">
        <Header user={user} />
        <main className="main-content">
          <p>Loading...</p>
        </main>
      </div>
    );
  }

  return (
    <div className="payments">
      <Header user={user} />
      <main className="main-content">
        <div className="payments-header">
          <h2>Payment History</h2>
          {user.plan === 'Basic' && (
            <p className="plan-info">
              You are currently on the Free plan. Upgrade to see your payment history.
            </p>
          )}
        </div>

        {error && <p className="error-message">{error}</p>}
        {message && <p className="info-message">{message}</p>}

        <div className="payments-container">
          {Object.entries(payments).length > 0 ? (
            Object.entries(payments)
              .sort(([a], [b]) => b.localeCompare(a))
              .map(([monthYear, monthPayments]) => (
                <div key={monthYear} className="month-group">
                  <h3 className="month-header">
                    {new Date(monthYear + '-01').toLocaleDateString('pt-BR', { 
                      month: 'long', 
                      year: 'numeric' 
                    })}
                  </h3>
                  
                  <div className="payments-list">
                    {monthPayments.map((payment) => (
                      <div key={payment.id} className="payment-card">
                        <div className="payment-info">
                          <div className="payment-main">
                            <h4>{payment.description || 'Assinatura Silent Puma'}</h4>
                            <span className="payment-amount">
                              {formatCurrency(payment.amount)}
                            </span>
                          </div>
                          <div className="payment-details">
                            <span className="payment-date">
                              {formatDate(payment.created)}
                            </span>
                            <span className={`payment-status ${payment.status}`}>
                              {payment.status === 'succeeded' ? 'Paid' : 
                               payment.status === 'pending' ? 'Pending' : 
                               'Failed'}
                            </span>
                          </div>
                          {payment.receipt_url && (
                            <a 
                              href={payment.receipt_url} 
                              target="_blank" 
                              rel="noopener noreferrer"
                              className="receipt-link"
                            >
                              View Receipt
                            </a>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))
          ) : (
            <div className="no-payments">
              {user.plan === 'Basic' ? (
                <>
                  <p>Free plan users don't have payment history</p>
                  <Link to="/settings" className="upgrade-btn">
                    Upgrade Plan
                  </Link>
                </>
              ) : (
                <p>No payment history found</p>
              )}
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

export default Payments; 